/* eslint-disable max-len */
import React from 'react';

interface ActiveNoiseCancellingProps {
    /**
     * A string representing the CSS class to be applied to the ActiveNoiseCancellingIcon element to override its default styling.
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     */
    color1?: string;
    /**
     * A number or string representing the height of the ActiveNoiseCancellingIcon element.
     */
    height?: number | string;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     */
    testId?: string;
    /**
     * A number or string representing the height of the ActiveNoiseCancellingIcon element.
     */
    width?: number | string;
}

/**
 * The `ActiveNoiseCancelling` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `ActiveNoiseCancellingProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the ActiveNoiseCancellingIcon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.color2    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the ActiveNoiseCancellingIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the ActiveNoiseCancellingIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const ActiveNoiseCancellingComponent = <ActiveNoiseCancelling className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const ActiveNoiseCancelling = React.forwardRef<SVGSVGElement, ActiveNoiseCancellingProps>((props, ref) => {
    const {className, color1, height, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 80 80"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g fill={color1}>
                <path d="M64.68 71.11a24.4 24.4 0 0 0-14.49-20.47 14 14 0 0 0 4.29-10.06 14.02 14.02 0 0 0-28.02 0c0 3.95 1.65 7.51 4.29 10.06a24.4 24.4 0 0 0-14.49 20.47l-.12 1.71 1.41.97a40 40 0 0 0 22.93 7.12c8.24 0 16.16-2.46 22.93-7.12l1.41-.97-.12-1.71zM32.46 40.58a8.01 8.01 0 1 1 16.03.01 8.01 8.01 0 0 1-16.03-.01m8.01 34.34a34 34 0 0 1-18.02-5.09 18.36 18.36 0 0 1 18.02-15.25c8.95 0 16.56 6.57 18.02 15.25a34 34 0 0 1-18.02 5.09" />
                <path d="M12.68 60.81A34.3 34.3 0 0 1 6 40.46 34.5 34.5 0 0 1 40.46 6a34.5 34.5 0 0 1 34.46 34.46 34.2 34.2 0 0 1-6.68 20.35 31 31 0 0 1 2.07 6.92 40.3 40.3 0 0 0 10.61-27.28A40.5 40.5 0 0 0 40.46 0 40.5 40.5 0 0 0 0 40.46c0 10.5 4.02 20.08 10.61 27.28a30 30 0 0 1 2.07-6.92z" />
            </g>
        </svg>
    );
});

ActiveNoiseCancelling.displayName = 'ActiveNoiseCancelling';
ActiveNoiseCancelling.defaultProps = {
    className: '',
    color1: '#212325',
    height: 80,
    testId: 'ActiveNoiseCancelling',
    width: 80
};