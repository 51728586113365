import Link from 'next/link';
import styled from 'styled-components';

import type {WithChildren} from 'types/global';

type LinkRel = 'nofollow noindex noopener noreferrer' | 'nofollow noindex noopener' | 'nofollow noindex noreferrer' |
'nofollow noindex' | 'nofollow noopener noreferrer' | 'nofollow noopener' | 'nofollow noreferrer' | 'nofollow' |
'noindex noopener noreferrer' | 'noindex noopener' | 'noindex noreferrer' | 'noindex' | 'noopener noreferrer' |
'noopener' | 'noreferrer';

/**
 * The `ComponentProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface ComponentProps {
    /**
     * URL for the link element to link to.
     */
    href: string;
    /**
     * Defines the relationship between the current document and the linked URL.
     * - `'nofollow'`: Indicates that the link is not endorsed by the original document's author.
     * - `'noindex'`: Indicates that the linked document should not be indexed by search engines.
     * - `'noopener'`: Ensures that the linked document does not have access to the originating document.
     * - `'noreferrer'`: Ensures that the linked document does not have access to the originating document and does not pass referrer information.
     * Also every combination of these values is possible.
     */
    rel?: LinkRel;
    /**
     * Specifies where to open the linked URL.
     * - `'_blank'`: Opens the linked document in a new window or tab.
     * - `'_parent'`: Opens the linked document in the parent frame.
     * - `'_self'`: Opens the linked document in the same frame as it was clicked.
     * - `'_top'`: Opens the linked document in the full body of the window.
     */
    target?: '_blank' | '_parent' | '_self' | '_top';
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * It is a required property and must be provided when an object of type `ComponentProps` is expected.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     */
    testId: string;
}

/**
 * Renders an inline text link within the content, providing a seamless way to navigate to other pages or external resources.
 * The `InlineTextLink` component creates a clickable text element that directs users to the specified URL when clicked. It allows
 * for customization of the link behavior through `rel` and `target` attributes, catering to different navigation needs, such as
 * opening the link in a new tab or ensuring secure referrals. This component is designed to integrate links within textual content
 * smoothly, enhancing the user experience by offering additional information or related actions without disrupting the reading flow.
 *
 * @param props          The component props.
 * @param props.children The content to be displayed as the clickable link.
 * @param props.href     The URL that the link points to.
 * @param props.rel      Specifies the relationship between the current document and the linked document. Useful for SEO and security.
 * @param props.target   Defines how the linked document will be displayed when clicked.
 * @param props.testId   A unique identifier for testing purposes, allowing for precise targeting of the component in tests.
 * @returns A JSX.Element that renders an inline text link, facilitating navigation within the application or to external sites.
 *
 * @example
 * ```tsx
 * <InlineTextLink href="https://example.com" rel="noopener noreferrer" target="_blank" testId="example-link">
 *   Visit Example
 * </InlineTextLink>
 * ```
 */
const InlineTextLink = ({children, href, rel, target, testId}: WithChildren<ComponentProps>) => (
    <StyledLink data-cy={testId} href={href} rel={rel} target={target}>{children}</StyledLink>
);

InlineTextLink.displayName = 'InlineTextLink';
InlineTextLink.defaultProps = {testId: 'InlineTextLink'};

export {InlineTextLink};

const StyledLink = styled(Link)`
    color: currentColor;
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }

    &:focus-visible {
        outline: 2px solid ${({theme}) => theme.colors.focusColor};
        outline-offset: 2px;
    }
`;