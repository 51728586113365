/* eslint-disable max-len */
import React from 'react';

interface SpatialAudioProps {
    /**
     * A string representing the CSS class to be applied to the SpatialAudioIcon element to override its default styling.
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     */
    color1?: string;
    /**
     * A number or string representing the height of the SpatialAudioIcon element.
     */
    height?: number | string;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     */
    testId?: string;
    /**
     * A number or string representing the height of the SpatialAudioIcon element.
     */
    width?: number | string;
}

/**
 * The `SpatialAudio` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `SpatialAudioProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the SpatialAudioIcon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.color2    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the SpatialAudioIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the SpatialAudioIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const SpatialAudioComponent = <SpatialAudio className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const SpatialAudio = React.forwardRef<SVGSVGElement, SpatialAudioProps>((props, ref) => {
    const {className, color1, height, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 80 80"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g>
                <path d="M72.51 40.19q4.44-5.12 4.57-11.43c.08-4.7-1.6-8.73-4.86-11.64-4.38-3.9-10.75-5.07-15.73-4.9C53.6 6.08 48.95 1.44 42.4.27c-7.84-1.39-14.28 2.5-17.63 10.45q-.6.14-1.17.4c-4.46-.89-8.6-.32-12.3 1.75-4.1 2.28-6.76 5.75-7.63 10.03-1.2 5.74 1 11.84 3.64 16.07-3.85 5.59-5.55 11.93-3.27 18.18 2.41 6.61 7.83 10.22 14.98 10.22q1.44 0 2.96-.2.47.51 1.06.9a17.5 17.5 0 0 0 7.63 9.6 15.5 15.5 0 0 0 7.98 2.3q2.29 0 4.54-.73c5.57-1.86 9.75-6.81 12.07-11.22q1.16.1 2.3.1c5.9 0 11.3-1.86 15.09-6.39 5.1-6.1 4.93-13.63-.3-20.49q.14-.52.2-1.06zm-45.75 22.1-.14-44.97 5.13 2.94 13.65 7.82.73.42 14.14 8.1 5.32 3.05-15.1 8.84zm41.3-40.5c1.9 1.7 2.81 3.96 2.76 6.87a11 11 0 0 1-1.77 5.76l-8.75-5.01v-.12q-.04-1.34-.15-2.68v-.03a46 46 0 0 0-1.37-8.08c3.35.2 6.93 1.19 9.29 3.3zM41.3 6.44c5.02.9 8.45 5.2 10.49 10.72l.3.87q.16.45.3.92.27.86.48 1.76l.18.8a48 48 0 0 1 .69 3.92q0 .12.03.23l-3.58-2.04-.94-.54-3.77-2.16-11.1-6.36-3.58-2.05c2.76-5.98 7.13-6.66 10.5-6.06M9.79 24.16c.52-2.5 2-4.41 4.54-5.83a11 11 0 0 1 5.89-1.35l.04 10.08-.1.07q-1.13.7-2.24 1.46l-.03.02a47 47 0 0 0-6.3 5.24c-1.52-3-2.45-6.6-1.81-9.7zm.12 30.86c-1.74-4.79.27-9.94 4.05-14.48l.54-.62.7-.79q.6-.63 1.26-1.27l.64-.58.95-.85.64-.54q.68-.55 1.38-1.08l.24-.19v.47l.02 4.73.02 4.35.07 16.91c-6.57.62-9.33-2.83-10.5-6.04zm38.77 11.87c-1.84 2.82-4.48 5.43-7.48 6.43-2.43.8-4.83.48-7.32-1.02a11 11 0 0 1-4.12-4.4l8.7-5.1.39.2 1.42.7q.51.26 1.04.5.8.36 1.62.7l.9.38a46 46 0 0 0 4.14 1.4zm19.15-9.15c-3.27 3.9-8.73 4.74-14.54 3.76l-.85-.15-1-.21q-.87-.21-1.76-.46l-.79-.24-1.24-.4-.78-.29q-.83-.3-1.65-.65l-.27-.11 4.5-2.63 1.38-.81 2.88-1.7 14.09-8.19c3.78 5.35 2.19 9.47 0 12.09z" fill={color1} />
            </g>
        </svg>
    );
});

SpatialAudio.displayName = 'SpatialAudio';
SpatialAudio.defaultProps = {
    className: '',
    color1: '#212325',
    height: 80,
    testId: 'SpatialAudio',
    width: 80
};