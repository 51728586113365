/* eslint-disable max-len */
import React from 'react';

interface EqualizerProps {
    /**
     * A string representing the CSS class to be applied to the EqualizerIcon element to override its default styling.
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     */
    color1?: string;
    /**
     * A number or string representing the height of the EqualizerIcon element.
     */
    height?: number | string;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     */
    testId?: string;
    /**
     * A number or string representing the height of the EqualizerIcon element.
     */
    width?: number | string;
}

/**
 * The `Equalizer` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `EqualizerProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the EqualizerIcon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.color2    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the EqualizerIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the EqualizerIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const EqualizerComponent = <Equalizer className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const Equalizer = React.forwardRef<SVGSVGElement, EqualizerProps>((props, ref) => {
    const {className, color1, height, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 76 82"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g fill={color1}>
                <path d="M10.81 0h-6v81.45h6z" />
                <path d="M7.81 32.86a7.81 7.81 0 1 0 0-15.62 7.81 7.81 0 0 0 0 15.62M40.77 0h-6v81.45h6z" />
                <path d="M37.77 68.63a7.81 7.81 0 1 0 0-15.62 7.81 7.81 0 0 0 0 15.62M70.74 0h-6v81.45h6z" />
                <path d="M67.74 46.61a7.81 7.81 0 1 0 0-15.62 7.81 7.81 0 0 0 0 15.62" />
            </g>
        </svg>
    );
});

Equalizer.displayName = 'Equalizer';
Equalizer.defaultProps = {
    className: '',
    color1: '#212325',
    height: 82,
    testId: 'Equalizer',
    width: 76
};