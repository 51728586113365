import {cubicBezier, type Variants} from 'framer-motion';

export const InViewPortTrigger: Variants = {
    /**
     * Defines the animation behavior for when the component enters the viewport.
     * It configures the animation to start with the specified stagger between children, animating them in sequence.
     *
     * @param stagger The time delay in seconds between the start of each child's animation, applied in a forward direction.
     * @returns An object configuring the animation properties for when the component is in view.
     */
    inView(stagger) {
        return {
            transition: {
                staggerChildren: stagger,
                staggerDirection: 1,
                when: 'beforeChildren'
            }
        };
    },
    /**
     * Defines the animation behavior for when the component moves out of the viewport.
     * It configures the animation to end with the specified stagger between children, animating them in reverse sequence.
     *
     * @param stagger The time delay in seconds between the end of each child's animation, applied in a reverse direction.
     * @returns An object configuring the animation properties for when the component is out of view.
     */
    outOfView(stagger) {
        return {
            transition: {
                staggerChildren: stagger,
                staggerDirection: -1,
                when: 'beforeChildren'
            }
        };
    }
};

export const SimpleFadeInAnimation: Variants = {
    inView: {
        opacity: 1,
        transition: {
            duration: 0.5,
            ease: cubicBezier(0.40, 0.80, 0.74, 1)
        }
    },
    outOfView: {opacity: 0}
};

export const FadeInAnimation: Variants = {
    inView: {
        opacity: 1,
        transition: {
            duration: 0.5,
            ease: cubicBezier(0.40, 0.80, 0.74, 1)
        },
        y: '0px'
    },
    outOfView: {
        opacity: 0,
        y: '50px'
    }
};

export const FadeInHitsDifferentAnimation: Variants = {
    inView: {
        opacity: 1,
        transition: {
            delay: 1.2,
            duration: 0.5,
            ease: cubicBezier(0.40, 0.80, 0.74, 1)
        },
        y: '0px'
    },
    outOfView: {
        opacity: 0,
        y: '50px'
    }
};

export const FadeInFitsDifferentAnimation: Variants = {
    inView: {
        opacity: 1,
        transition: {
            delay: 2.16,
            duration: 0.5,
            ease: cubicBezier(0.40, 0.80, 0.74, 1)
        },
        y: '0px'
    },
    outOfView: {
        opacity: 0,
        y: '50px'
    }
};