import {useCallback, useEffect, useState} from 'react';

import {useScreenSize} from '@nfq/react-grid';
import useEmblaCarousel from 'embla-carousel-react';

import type {EmblaCarouselType, EmblaOptionsType, EmblaPluginType} from 'embla-carousel';

/**
 * A custom React hook that provides slider functionality using the `useEmblaCarousel` library. This hook initializes the
 * carousel with optional settings, manages navigation state (next and previous button enablement), and tracks the current
 * index of the slider. It exposes a comprehensive set of states and functions that can be utilized by slider components to
 * implement navigation, dynamically update based on the carousel's state, and respond to user interactions effectively.
 *
 * @param options Optional configuration settings for the Embla carousel. These settings can customize various aspects of the slider, such as the speed of transition, the spacing between slides, and whether or not the carousel loops.
 * @param plugins Optional plugins to enhance the functionality of the Embla carousel. These plugins can add features like autoplay.
 * @returns An object containing states and functions for managing and interacting with the slider. This includes the current index
 * of the selected slide, boolean states indicating if the next or previous buttons should be disabled, functions to move
 * to the next or previous slide, a ref object to be attached to the slider container for initialization, and an array of
 * snap points indicating the scroll positions of each slide.
 *
 * @example
 * ```ts
 * const {
 *   slideRef,
 *   onPrevButtonClick,
 *   onNextButtonClick,
 *   prevBtnDisabled,
 *   nextBtnDisabled,
 * } = useSlider({ loop: false });
 * ```
 */
export const useSlider = (options?: EmblaOptionsType, plugins?: EmblaPluginType[]) => {
    const [emblaRef, emblaApi] = useEmblaCarousel(options, plugins);
    const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
    const [nextBtnDisabled, setNextBtnDisabled] = useState(true);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);
    const breakpoint = useScreenSize();

    const isMobile = ['xs', 'sm'].includes(breakpoint);
    // eslint-disable-next-line @nfq/no-magic-numbers
    const iconSize = isMobile ? 35 : 50;

    const onPrevButtonClick = useCallback(() => {
        if (!emblaApi) return;
        emblaApi.scrollPrev();
    }, [emblaApi]);

    const onNextButtonClick = useCallback(() => {
        if (!emblaApi) return;
        emblaApi.scrollNext();
    }, [emblaApi]);

    const onInit = useCallback((api: EmblaCarouselType) => {
        setScrollSnaps(api.scrollSnapList());
    }, []);

    const onSelect = useCallback((api: EmblaCarouselType) => {
        setPrevBtnDisabled(!api.canScrollPrev());
        setNextBtnDisabled(!api.canScrollNext());
        setSelectedIndex(api.selectedScrollSnap());
    }, []);

    useEffect(() => {
        if (!emblaApi) return;

        onInit(emblaApi);
        onSelect(emblaApi);
        emblaApi.on('reInit', onInit);
        emblaApi.on('reInit', onSelect);
        emblaApi.on('select', onSelect);
    }, [emblaApi, onInit, onSelect]);

    return {
        iconSize,
        nextBtnDisabled,
        onNextButtonClick,
        onPrevButtonClick,
        prevBtnDisabled,
        scrollSnaps,
        selectedIndex,
        slideRef: emblaRef
    };
};