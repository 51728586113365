/* eslint-disable max-len */
import React from 'react';

interface DynamicHeadtrackingProps {
    /**
     * A string representing the CSS class to be applied to the DynamicHeadtrackingIcon element to override its default styling.
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     */
    color1?: string;
    /**
     * A number or string representing the height of the DynamicHeadtrackingIcon element.
     */
    height?: number | string;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     */
    testId?: string;
    /**
     * A number or string representing the height of the DynamicHeadtrackingIcon element.
     */
    width?: number | string;
}

/**
 * The `DynamicHeadtracking` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `DynamicHeadtrackingProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the DynamicHeadtrackingIcon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.color2    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the DynamicHeadtrackingIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the DynamicHeadtrackingIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const DynamicHeadtrackingComponent = <DynamicHeadtracking className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const DynamicHeadtracking = React.forwardRef<SVGSVGElement, DynamicHeadtrackingProps>((props, ref) => {
    const {className, color1, height, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 80 80"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g fill={color1}>
                <path d="m39.93 20.21-.7-.01a17.7 17.7 0 0 1-13.08-6.18 3 3 0 0 1 4.61-3.85 11.8 11.8 0 0 0 8.68 4.02c3.92.13 7.73-1.39 10.41-4.18a3 3 0 1 1 4.33 4.15 19.7 19.7 0 0 1-14.25 6.03zm.31-12.67a4.27 4.27 0 1 0 0-8.54 4.27 4.27 0 0 0 0 8.54m29.28 35.09q-.65 0-1.29-.29a19.8 19.8 0 0 1-10.42-12.29 17.8 17.8 0 0 1 1.75-14.36 3 3 0 0 1 4.13-.96 3 3 0 0 1 .96 4.13 11.8 11.8 0 0 0-1.09 9.5 13.8 13.8 0 0 0 7.24 8.56 3 3 0 0 1-1.29 5.71zm4.69-12.69a4.27 4.27 0 1 0 0-8.54 4.27 4.27 0 0 0 0 8.54M9.95 42.63a3 3 0 0 1-1.29-5.71 13.8 13.8 0 0 0 7.24-8.56c.98-3.35.59-6.81-1.09-9.5a3 3 0 1 1 5.09-3.17 17.8 17.8 0 0 1 1.75 14.36 19.7 19.7 0 0 1-10.42 12.29q-.63.3-1.29.29zM5.27 29.94a4.27 4.27 0 1 0 0-8.54 4.27 4.27 0 0 0 0 8.54m58.68 40.29a24.4 24.4 0 0 0-14.49-20.47 14 14 0 0 0 4.29-10.06 14.02 14.02 0 0 0-28.02 0c0 3.95 1.65 7.51 4.29 10.06a24.4 24.4 0 0 0-14.49 20.47l-.12 1.71 1.41.97a40 40 0 0 0 22.93 7.12c8.24 0 16.16-2.46 22.93-7.12l1.41-.97-.12-1.71zM31.73 39.7a8.01 8.01 0 1 1 16.03.01 8.01 8.01 0 0 1-16.03-.01m8.01 34.34a34 34 0 0 1-18.02-5.09A18.36 18.36 0 0 1 39.74 53.7c8.95 0 16.56 6.57 18.02 15.25a34 34 0 0 1-18.02 5.09" />
            </g>
        </svg>
    );
});

DynamicHeadtracking.displayName = 'DynamicHeadtracking';
DynamicHeadtracking.defaultProps = {
    className: '',
    color1: '#212325',
    height: 80,
    testId: 'DynamicHeadtracking',
    width: 80
};