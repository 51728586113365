/* eslint-disable max-len */
import React from 'react';

interface LosslessAudioProps {
    /**
     * A string representing the CSS class to be applied to the LosslessAudioIcon element to override its default styling.
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     */
    color1?: string;
    /**
     * A number or string representing the height of the LosslessAudioIcon element.
     */
    height?: number | string;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     */
    testId?: string;
    /**
     * A number or string representing the height of the LosslessAudioIcon element.
     */
    width?: number | string;
}

/**
 * The `LosslessAudio` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `LosslessAudioProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the LosslessAudioIcon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.color2    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the LosslessAudioIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the LosslessAudioIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const LosslessAudioComponent = <LosslessAudio className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const LosslessAudio = React.forwardRef<SVGSVGElement, LosslessAudioProps>((props, ref) => {
    const {className, color1, height, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 80 80"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g fill={color1}>
                <path d="M39.06 59.99c4.23-4.24 11.05-6.57 18.65-7.08a2.4 2.4 0 0 0 2.2-2.4 2.4 2.4 0 0 0-2.2-2.42c-7.42-.47-14.06-2.75-18.25-6.93-4.18-4.19-6.6-11.2-7.08-18.9a2.4 2.4 0 0 0-2.4-2.22h-.4a2.4 2.4 0 0 0-2.4 2.22c-.45 7.52-2.72 14.26-6.95 18.48-4.22 4.23-10.7 6.45-18.05 7.04A2.4 2.4 0 0 0 0 50.18v.1c0 1.23.94 2.3 2.19 2.4 7.17.55 13.57 2.81 17.64 6.89 4.15 4.15 6.49 10.8 7.05 18.23a2.4 2.4 0 0 0 2.4 2.18h.47a2.4 2.4 0 0 0 2.4-2.2c.54-7.24 2.8-13.7 6.9-17.81zm27-33.29c2.83-2.82 7.38-4.38 12.46-4.73a1.6 1.6 0 0 0 1.48-1.6 1.6 1.6 0 0 0-1.48-1.62c-4.95-.32-9.4-1.84-12.18-4.64-2.8-2.8-4.42-7.5-4.74-12.63A1.6 1.6 0 0 0 59.98 0h-.25a1.6 1.6 0 0 0-1.61 1.49c-.3 5.02-1.82 9.53-4.65 12.36s-7.15 4.31-12.08 4.7a1.6 1.6 0 0 0-1.45 1.6v.07c0 .83.63 1.54 1.45 1.6 4.8.37 9.07 1.88 11.8 4.6 2.78 2.79 4.34 7.23 4.7 12.2a1.6 1.6 0 0 0 1.6 1.45h.33a1.6 1.6 0 0 0 1.6-1.47c.36-4.83 1.87-9.16 4.62-11.91z" />
            </g>
        </svg>
    );
});

LosslessAudio.displayName = 'LosslessAudio';
LosslessAudio.defaultProps = {
    className: '',
    color1: '#212325',
    height: 80,
    testId: 'LosslessAudio',
    width: 80
};