/* eslint-disable max-len */
import React from 'react';

import styled from 'styled-components';

import {chooseTheme} from 'UI/utils/helpers';

interface ElectronicPartnerProps {
    /**
     * A string representing the CSS class to be applied to the ElectronicPartnerIcon element to override its default styling.
     */
    className?: string;
    /**
     * A number or string representing the height of the ElectronicPartnerIcon element.
     */
    height?: number | string;
    /**
     * A boolean representing if the icon is light.
     */
    isLight?: boolean;
    /**
     * Defines if the icon reacts to the system theme.
     */
    isReactingToTheme?: boolean;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     */
    testId?: string;
    /**
     * A number or string representing the height of the ElectronicPartnerIcon element.
     */
    width?: number | string;
}

/**
 * The `ElectronicPartner` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `ElectronicPartnerProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props                   The component props.
 * @param props.className         A string representing the CSS class to be applied to the ElectronicPartnerIcon element to override its default styling.
 * @param props.height            A number or string representing the height of the ElectronicPartnerIcon element.
 * @param props.isReactingToTheme Defines if the icon reacts to the system theme.
 * @param props.testId            A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width             A number or string representing the height of the ElectronicPartnerIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const ElectronicPartnerComponent = <ElectronicPartner className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const ElectronicPartner = React.forwardRef<SVGSVGElement, ElectronicPartnerProps>((props, ref) => {
    const {className, height, isLight, isReactingToTheme, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 300 100"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <StyledGroup $isLight={isLight} $reactsToTheme={isReactingToTheme}>
                <path d="M64.67 76.13h60l3.81-18.36H90.56l1.9-8.94h34.52l3.51-17.05H96.08l1.9-8.94h37.12l3.72-18.36h-59.2z" />
                <path clipRule="evenodd" d="M168.3 22.84h7.52c4.62 0 9.53.9 9.53 6.43 0 4.81-2.9 8.12-11.74 8.12h-8.43zm-33.01 53.29h21.77l4.51-21.68h14.85c18.87 0 31-12.44 31-26.89 0-15.85-8.52-23.08-21.67-23.08h-35.51zM237.5 25.45h-20.56l-4.12 19.87h20.57zm-31.1 50.68h20.57l4.12-19.87h-20.57z" fillRule="evenodd" />
                <path d="M60.48 96.3h10.6l.67-3.25h-6.7l.34-1.57h6.1l.61-3.02h-6.07l.33-1.57h6.56l.65-3.24H63.12zm12.47 0h3.51l2.62-12.65h-3.5z" />
                <path clipRule="evenodd" d="M85.03 93.62c-.17.32-.62.66-1.38.66-1.1 0-1.44-.82-1.44-1.8h6.56c.16-.56.18-1.1.18-1.59 0-2.88-2.47-4.02-4.5-4.02-2.29 0-5.51 1.38-5.51 5.6 0 2.23 1.31 4.06 4.57 4.06 3.97 0 4.66-2.3 4.89-2.91zm-2.51-2.96c.26-.97.88-1.54 1.82-1.54.89 0 1.33.55 1.33 1.24q0 .15-.04.3z" fillRule="evenodd" />
                <path d="M100.04 90.6c.05-2.58-1.85-3.73-4.4-3.73-3.5 0-5.54 2.32-5.54 5.46 0 1.72.78 4.2 4.68 4.2 2.65 0 4.34-1.28 4.94-3.9h-3.37c-.2.51-.58 1.34-1.54 1.34-1 0-1.2-.8-1.2-1.68s.4-2.87 1.93-2.87q.6-.02.88.27.29.25.25.9zm6.64-6.28h-3.5l-.63 2.78h-1.52l-.5 2.16h1.56l-.81 3.77c-.1.43-.2.77-.2 1.26 0 .68.36 1.4.96 1.74.67.37 1.47.37 2.21.37q.97-.06 1.9-.23l.53-2.53c-.29.02-.59.05-1.15.05s-.71-.25-.71-.55.09-.62.16-.92l.62-2.96h1.86l.5-2.16h-1.9zm.64 11.98h3.5l.8-3.85c.4-1.93 1.15-2.57 2.64-2.57.44 0 .92.07 1.35.23l.65-3.1c-.32-.1-.65-.14-1.24-.14-.9 0-2.05.7-2.67 1.66h-.04l.3-1.43h-3.4z" />
                <path clipRule="evenodd" d="M115.68 92.22c0 3.08 2.05 4.3 4.58 4.3 4.24 0 5.55-3.27 5.55-5.63 0-2.78-1.77-4.02-4.47-4.02-3.75 0-5.66 2.41-5.66 5.35m3.5.27c0-.96.5-3.07 1.9-3.07.94 0 1.22.62 1.22 1.72 0 .8-.5 2.83-1.88 2.83-.99 0-1.24-.74-1.24-1.48" fillRule="evenodd" />
                <path d="M126.34 96.3h3.5l1.03-4.88c.22-.95.59-1.75 1.7-1.75.57 0 1 .3 1 .88 0 .38-.18 1-.29 1.53l-.9 4.22h3.5l1.12-5.2c.15-.67.25-1.34.25-1.75 0-1.8-1.38-2.48-3-2.48-1.1 0-2.17.57-2.84 1.4h-.04l.27-1.17h-3.39z" />
                <path clipRule="evenodd" d="M144.04 83.65h-3.51L140 86.1h3.51zM137.9 96.3h3.5l1.92-9.2h-3.51z" fillRule="evenodd" />
                <path d="M153.83 90.6c.05-2.58-1.84-3.73-4.4-3.73-3.5 0-5.54 2.32-5.54 5.46 0 1.72.78 4.2 4.68 4.2 2.65 0 4.34-1.28 4.94-3.9h-3.36c-.2.51-.59 1.34-1.55 1.34-1 0-1.2-.8-1.2-1.68s.4-2.87 1.93-2.87q.6-.02.89.27.28.25.25.9z" />
                <path clipRule="evenodd" d="M159.82 86.89h1.32c.82 0 1.69.16 1.69 1.13 0 .85-.52 1.44-2.08 1.44h-1.48zm-5.83 9.4h3.84l.8-3.82h2.62c3.33 0 5.47-2.2 5.47-4.75 0-2.8-1.5-4.07-3.82-4.07h-6.27zM171 89.88c.19-.69.68-.94 1.4-.94.53 0 1.1.16 1.1.8 0 1.13-1.59 1-2.58 1.06-2.64.2-4.44.85-4.44 3.28 0 1.75 1.4 2.45 2.97 2.45 1.03 0 2.22-.38 2.84-1.12h.03q-.01.45.13.89h3.43c-.14-.43-.07-.11-.14-.82 0-.6 1.12-4.73 1.12-6.17 0-2.23-2.8-2.44-4.47-2.44-2.25 0-4.14.57-4.7 3.01zm-1.02 3.78c0-.68.55-.98 1.14-1.03.64-.06 1.3-.13 1.86-.41-.21 1.36-.7 2.23-1.88 2.23-.57 0-1.12-.16-1.12-.8" fillRule="evenodd" />
                <path d="M177.53 96.3h3.5l.8-3.85c.41-1.93 1.16-2.57 2.65-2.57.44 0 .92.07 1.34.23l.66-3.1c-.32-.1-.66-.14-1.24-.14-.9 0-2.06.7-2.68 1.66h-.03l.3-1.43h-3.4zm14.97-11.98h-3.51l-.62 2.78h-1.52l-.5 2.16h1.56l-.81 3.77c-.1.43-.2.77-.2 1.26 0 .68.35 1.4.95 1.74.68.37 1.47.37 2.22.37q.96-.06 1.9-.23l.53-2.53c-.29.02-.59.05-1.16.05-.54 0-.7-.25-.7-.55s.09-.62.16-.92l.61-2.96h1.87l.5-2.16h-1.9zm.64 11.98h3.5l1.03-4.88c.21-.95.58-1.75 1.7-1.75.57 0 1 .3 1 .88 0 .38-.19 1-.3 1.53l-.9 4.22h3.51l1.12-5.2c.14-.67.25-1.34.25-1.75 0-1.8-1.38-2.48-3-2.48-1.11 0-2.18.57-2.85 1.4h-.04l.27-1.17h-3.38z" />
                <path clipRule="evenodd" d="M211.2 93.62c-.17.32-.62.66-1.38.66-1.1 0-1.43-.82-1.43-1.8h6.55c.16-.56.18-1.1.18-1.59 0-2.88-2.46-4.02-4.5-4.02-2.29 0-5.51 1.38-5.51 5.6 0 2.23 1.31 4.06 4.57 4.06 3.97 0 4.66-2.3 4.89-2.91zm-2.51-2.96c.26-.97.88-1.54 1.82-1.54.89 0 1.33.55 1.33 1.24l-.03.3z" fillRule="evenodd" />
                <path d="M215.78 96.3h3.5l.8-3.85c.4-1.93 1.15-2.57 2.64-2.57.44 0 .92.07 1.34.23l.66-3.1c-.32-.1-.66-.14-1.24-.14-.9 0-2.05.7-2.67 1.66h-.04l.3-1.43h-3.4z" />
            </StyledGroup>
        </svg>
    );
});

ElectronicPartner.displayName = 'ElectronicPartner';
ElectronicPartner.defaultProps = {
    className: '',
    height: 100,
    testId: 'ElectronicPartner',
    width: 300
};

interface StyledGroupProps {
    $isLight?: boolean;
    $reactsToTheme?: boolean;
}

const StyledGroup = styled.g<StyledGroupProps>`
    fill: ${({$isLight}) => chooseTheme($isLight ? 'iconColorLight' : 'iconColor')};
`;