/* eslint-disable max-len */
import React from 'react';

import styled from 'styled-components';

import {chooseTheme} from 'UI/utils/helpers';

interface ExpertProps {
    /**
     * A string representing the CSS class to be applied to the ExpertIcon element to override its default styling.
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     */
    color1?: string;
    /**
     * A string representing the color to be applied.
     */
    color2?: string;
    /**
     * A number or string representing the height of the ExpertIcon element.
     */
    height?: number | string;
    /**
     * A boolean representing if the icon is light.
     */
    isLight?: boolean;
    /**
     * Defines if the icon reacts to the system theme.
     */
    isReactingToTheme?: boolean;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     */
    testId?: string;
    /**
     * A number or string representing the height of the ExpertIcon element.
     */
    width?: number | string;
}

/**
 * The `Expert` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `ExpertProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props                   The component props.
 * @param props.className         A string representing the CSS class to be applied to the ExpertIcon element to override its default styling.
 * @param props.color1            A string representing the color to be applied to the Icon.
 * @param props.color2            A string representing the color to be applied to the Icon.
 * @param props.height            A number or string representing the height of the ExpertIcon element.
 * @param props.isReactingToTheme Defines if the icon reacts to the system theme.
 * @param props.testId            A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width             A number or string representing the height of the ExpertIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const ExpertComponent = <Expert className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const Expert = React.forwardRef<SVGSVGElement, ExpertProps>((props, ref) => {
    const {className, color1, color2, height, isLight, isReactingToTheme, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 300 100"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <StyledPath $isLight={isLight} $reactsToTheme={isReactingToTheme} d="M184.22 35.62h11.58v5.01h6.05v7.18h-6.05v6.84c0 2.15 1.1 3.01 4.16 3.01h1.7v6.84h-11.78c-2.12 0-5.66 0-5.66-6.3zm-24.7 5.27h11.96v2.2s-.11.93 1.11-.42c1.94-2.15 4.7-2.2 5.4-2.2h1.19v8.62h-3.15c-.52 0-1.99-.01-2.9.67-.7.52-1.9 1.36-1.9 4.2V64.5h-11.71zm-121.38 0 6.84 11.67-7.03 11.94h14.76l6.02-9.34 5.7 9.34h14.99l-7.07-11.94 7.12-11.67h-15l-5.74 8.97-5.64-8.97zm98.97 3.17c3.9 0 3.37 3.85 3.37 4.8h-6.79c0-1.54-.27-4.8 3.42-4.8m15.37 9.4s.23-4.05-.44-6.42c-.66-2.37-1.76-4.22-3.56-5.22s-5.2-1.63-11.13-1.63c0 0-5.12-.16-9.16.7a9.2 9.2 0 0 0-5.65 3.42 13 13 0 0 0-1.9 6.35c-.09 3.22 0 3.56.2 5.5.19 1.94.88 4.66 2.73 6.33 1.88 1.68 2.68 2.67 13.06 2.67 6.32 0 9.08-.52 10.44-1.01.88-.31 1.62-.66 1.9-.8a6 6 0 0 0 1.8-1.63q.66-.84 1.2-2.44s.31-1.17.38-2.3l.04-.82h-11.91s.25 3.27-1.1 4.31c-.97.75-1.51.76-2.08.76s-1.78.1-2.49-.79c-.68-.85-1.12-1.63-1.12-3.67v-3.31zm-133.01-9.4c3.88 0 3.36 3.85 3.36 4.8h-6.78c0-1.54-.28-4.8 3.42-4.8m15.36 9.4s.23-4.05-.43-6.42c-.67-2.37-1.77-4.22-3.57-5.22s-5.2-1.63-11.13-1.63c0 0-5.12-.16-9.16.7a9.2 9.2 0 0 0-5.64 3.42c-.43.57-1.8 3.13-1.9 6.35s0 3.56.19 5.5.88 4.66 2.73 6.33c1.88 1.68 2.68 2.67 13.07 2.67 6.32 0 9.07-.52 10.43-1.01.88-.31 1.62-.66 1.91-.8a6 6 0 0 0 1.79-1.63q.66-.84 1.2-2.44c-.01 0 .32-1.17.38-2.3l.05-.82H22.83s.25 3.27-1.1 4.31c-.96.75-1.51.76-2.08.76s-1.78.1-2.48-.79c-.69-.85-1.12-1.63-1.12-3.67v-3.31zm64.85 5.64c-3.41 0-3.17-3.67-3.17-4.81v-3.77c0-1.55-.11-4.8 3.2-4.8 3.2 0 3.2 2.99 3.15 4.35v4.22c0 1.54.26 4.8-3.18 4.8m15.65-6.75c0-11.76-5.52-12.26-9.96-12.26-1.85 0-5.82-.12-7.64 2.23-1.34 1.74-1.27.24-1.27.24v-1.67H84.3v30.35h12.16v-7.69c0-1.33.42-1.06.65-.82s.71.7 1.2 1.01c.62.41 1.56 1.42 6.02 1.42 7.47 0 11-3.13 11-12.81" />
            <path d="M217.45 50a39.8 39.8 0 1 1 79.58.01 39.8 39.8 0 0 1-79.58-.01" fill={color2} />
            <path d="m262.06 54.42-1.9-3.5h19.8l1.89 3.5zm2.72 5.02-1.9-3.5h19.79l1.9 3.5zm22.52 5.05h-19.78l-1.92-3.53h19.78zm-34.6-10.07 1.9-3.5h-19.8l-1.89 3.5zm-2.72 5.02 1.9-3.5h-19.79l-1.9 3.5zm-22.52 5.05h19.79l1.9-3.53h-19.78zm24.97-18.75 1.9 3.5h-19.8l-1.89-3.5zm-2.72-5.02 1.9 3.5h-19.8l-1.88-3.5zm-22.52-5.05h19.78l1.92 3.53H229.1zm34.6 10.07-1.9 3.5h19.8l1.89-3.5zm2.72-5.02-1.9 3.5h19.79l1.9-3.5zm22.52-5.05h-19.79l-1.9 3.53h19.78zM254.1 24.36l3.02-5.16 3 5.16zm-2.97 5.1 2.09-3.58H261l2.08 3.57zm-2.93 5 2.24-3.82h13.34l2.23 3.83zm11.9 41.18-3 5.16-3.02-5.16zm2.98-5.1-2.09 3.58h-7.79l-2.08-3.57zm2.93-5-2.24 3.82h-13.34l-2.23-3.83z" fill={color1} />
            <path d="M220.66 50h1.2c0-9.77 3.96-18.6 10.36-25.01a35.3 35.3 0 0 1 25.02-10.37c9.78 0 18.62 3.96 25.02 10.37a35.3 35.3 0 0 1 10.37 25c0 9.78-3.96 18.62-10.37 25.02a35.3 35.3 0 0 1-25.02 10.36 35.3 35.3 0 0 1-25.02-10.36A35.3 35.3 0 0 1 221.86 50zh-1.2a37.78 37.78 0 1 0 75.56-.01 37.78 37.78 0 0 0-75.56 0z" fill={color1} />
        </svg>
    );
});

Expert.displayName = 'Expert';
Expert.defaultProps = {
    className: '',
    color1: '#000',
    color2: '#fff',
    height: 100,
    testId: 'Expert',
    width: 300
};

interface StyledPathProps {
    $isLight?: boolean;
    $reactsToTheme?: boolean;
}

const StyledPath = styled.path<StyledPathProps>`
    fill: ${({$isLight}) => chooseTheme($isLight ? 'iconColorLight' : 'iconColor')};
`;