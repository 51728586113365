/* eslint-disable max-len */
import React from 'react';

import styled from 'styled-components';

import {chooseTheme} from 'UI/utils/helpers';

interface MediaMarktProps {
    /**
     * A string representing the CSS class to be applied to the MediaMarktIcon element to override its default styling.
     */
    className?: string;
    /**
     * A number or string representing the height of the MediaMarktIcon element.
     */
    height?: number | string;
    /**
     * A boolean representing if the icon is light.
     */
    isLight?: boolean;
    /**
     * Defines if the icon reacts to the system theme.
     */
    isReactingToTheme?: boolean;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     */
    testId?: string;
    /**
     * A number or string representing the height of the MediaMarktIcon element.
     */
    width?: number | string;
}

/**
 * The `MediaMarkt` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `MediaMarktProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props                   The component props.
 * @param props.className         A string representing the CSS class to be applied to the MediaMarktIcon element to override its default styling.
 * @param props.height            A number or string representing the height of the MediaMarktIcon element.
 * @param props.isReactingToTheme Defines if the icon reacts to the system theme.
 * @param props.testId            A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width             A number or string representing the height of the MediaMarktIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const MediaMarktComponent = <MediaMarkt className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const MediaMarkt = React.forwardRef<SVGSVGElement, MediaMarktProps>((props, ref) => {
    const {className, height, isLight, isReactingToTheme, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 300 100"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <StyledGroup $isLight={isLight} $reactsToTheme={isReactingToTheme}>
                <path d="M300 80.05H123.78l13.38-15.5h12.6l27.42-31.73H300zm-170.5-2.62h167.87V35.45h-119L150.96 67.2h-12.6l-8.86 10.25z" />
                <path d="M150.96 67.18H0V19.95h191.77zM2.62 64.56h147.14l36.27-41.99H2.63zm226.02-17.42h5.3l3.32 18.73h-4.12l-1.7-14.49h-.19l-1.77 14.49h-4.15zm37.55 9.2v.27l2.25 9.23h4.1l-2.4-8.13v-.06l.09-.07c1.1-.91 1.73-2.58 1.73-4.65v-.32a5.6 5.6 0 0 0-1.52-4.1 5 5 0 0 0-3.64-1.39h-5.16v18.72h3.96V50.18h.63c1.48 0 1.77 1.54 1.8 2.6v.92c-.07 1.8-.86 2.43-1.48 2.56h-.02l-.33.06m-52.27-4.29c0 2.93 1.78 4.4 3.42 5.72l.43.34c1.44 1.18 2.26 2.36 2.26 3.3v.08c0 1.35-.56 1.65-1.15 1.65s-1.2-.58-1.2-1.73v-1.4h-3.92v1.22c0 3 2.06 5.01 5.12 5.01 3.28 0 5.22-1.82 5.22-4.9v-.17c0-2.98-1.3-4.14-3.36-5.91l-.91-.78c-1.03-.86-1.84-1.64-1.84-2.67v-.1c0-.57.19-1.58 1.16-1.58.9 0 1.12 1.16 1.12 1.77v1.26h3.8v-1.28c0-3.08-1.93-5.08-4.92-5.08-2.51 0-5.23 1.57-5.23 5v.26zm22.84-4.89v3.24h2.97v15.48h4V50.38h2.55v-3.24zm18.04 0v14.2c0 1.42-.75 1.61-1.15 1.61-.68 0-1.16-.57-1.16-1.61v-14.2h-4V61.8c0 2.68 2.03 4.42 5.16 4.42s5.15-1.73 5.15-4.42V47.14zm27.95 0 .21 13.4h-.1l-3.05-13.4h-5.25v18.73h4l-.33-13.45h.1l3.1 13.45h5.2V47.14zm-79.68 7.88.04.6v.44l.04.03c2.11 1.73 4.36 4.1 4.74 5.82q.16.74-.1 1.28-.36.65-1.24.95c-3.02 1.03-10.23-.95-16.11-3.86-4.2-2.08-7.9-4.56-10.39-6.98-2.32-2.25-2.95-4-2.35-5.07q.35-.6 1.15-.9c1.92-.74 5.6-.22 9.22.85a10 10 0 0 0-3.47 4.73c-.16 1.59 2.13 3.65 4.43 4.8l.13.05-.02-.17c-.55-3.92 2.43-9.8 5.56-11.1l.4-.14-1.15-.07a9.4 9.4 0 0 0-5.02 1.32c-6.05-2.07-10.47-2.32-12.32-1.7q-1.1.37-1.48 1.12-.39.76 0 1.93c.84 2.51 4.52 5.95 9.18 9a9.8 9.8 0 0 0 8.64 7.72h.07l-.25-.19a9.4 9.4 0 0 1-3.12-4.44l.48.24c7.37 3.64 15.1 5.5 18.52 4.36.77-.25 1.33-.62 1.59-1.14.24-.49.2-1.15-.05-1.88-.64-1.92-3.4-4.85-6.9-7.47l-.2-.16" />
                <path d="m65.04 39.9.58-3.27h-2.78l-.58 3.26zm18.81 4.93c1.27.61 2.95.75 3.92-.36-.18.11-.68.44-1.42.5-1.14.1-2.2-.55-2.95-1.37-1.66-1.82-2.42-4.88-1.7-8.07a10 10 0 0 0-2.31 2.36c.34 3 1.88 5.72 4.46 6.94m2.54-2.49c.12-.17.49-.65 1.15-.96 1.04-.47 2.27-.24 3.24.27 2.18 1.15 3.94 3.76 4.37 7a10 10 0 0 0 1.36-3c-1.35-2.72-3.72-4.74-6.57-5.01-1.4-.14-3.02.3-3.55 1.69" />
                <path d="M86.89 44.5a3 3 0 0 1-1.4-.52c-.94-.66-1.35-1.84-1.4-2.94-.09-2.46 1.3-5.28 3.9-7.29a10 10 0 0 0-3.3.33c-1.66 2.53-2.23 5.58-1.04 8.2.57 1.27 1.77 2.46 3.24 2.23" />
                <path d="M87.63 39.55c-1.16.8-2.11 2.18-1.64 3.57-.01-.2-.04-.8.27-1.48.5-1.02 1.58-1.63 2.66-1.87 2.4-.53 5.43.34 7.84 2.56q-.15-1.66-.89-3.18c-2.77-1.21-5.89-1.23-8.25.38z" />
                <path d="M85.14 40.19c-.38 1.35-.22 3.03 1.04 3.8-.15-.16-.56-.6-.74-1.32-.3-1.1.16-2.27.84-3.15 1.5-1.94 4.38-3.22 7.65-3.08a10 10 0 0 0-2.73-1.87c-2.9.86-5.3 2.85-6.06 5.6zm44.33.04-.15.85a4.8 4.8 0 0 0-2.95-.94c-2.72.03-5.28 2.07-6.38 4.7l1.94-10.69h-3.3l-8.7 11.56 2.1-11.56h-2.86L93.43 52.4h3.95l10.83-12.55-2.3 12.55h2.61l9.45-12.54-2.28 12.54h2.92l.81-4.51c.13 2.74 1.97 4.86 4.65 4.83a6.6 6.6 0 0 0 3.3-.98l-.12.65h2.78l2.21-12.18h-2.78zm-.73 6.3a4.34 4.34 0 0 1-4.12 3.49c-1.67 0-2.86-1.6-2.5-3.4.42-2.07 2.05-3.62 4.02-3.62s3 1.55 2.6 3.53" />
                <path d="M87.2 41.99a3 3 0 0 1 1.5.01c1.1.3 1.9 1.27 2.31 2.3.94 2.27.6 5.4-1.15 8.16a10 10 0 0 0 2.98-1.43c.7-2.93.19-6-1.82-8.05-.98-1.01-2.52-1.71-3.8-1zm63-1.76.66-3.6h-2.79l-.65 3.6h-4.35l-2.82 3.47 1.28-7.07h-2.78l-.63 3.46h-.33c-.67 0-1.54.26-2.35.74l.1-.61h-2.77l-2.22 12.18h2.78l1.27-6.99c.44-1.96 1.6-2.68 2.89-2.68h.15l-1.76 9.67h2.78l.93-5.07 3.04 5.07h5.36l1.74-9.57h2.62l.47-2.6zm-4.8 11.17-3.43-5.7 3.86-4.74-.34 1.86h1.47zM59.55 36.64l-.83 4.53a4.8 4.8 0 0 0-3.05-1.03c-2.86.03-5.54 2.31-6.52 5.17.02-2.97-2-5.17-4.86-5.17-2.66 0-5.3 1.88-6.5 4.36l1.88-10.35h-3.3l-8.7 11.56 2.1-11.56H26.9L11.16 52.4h3.96l10.82-12.55-2.3 12.55h2.62l9.44-12.54-2.27 12.54h2.91l.8-4.39c.25 2.67 2.14 4.71 4.93 4.71 2.8 0 5.24-1.86 6.4-4.45h-2.94a3.7 3.7 0 0 1-3.1 1.74 2.54 2.54 0 0 1-2.56-2.4h8.9c0 2.88 1.87 5.13 4.62 5.11a6.5 6.5 0 0 0 3.41-1.07l-.14.74h2.78l2.87-15.77h-2.78zm-18.86 8.25a3.9 3.9 0 0 1 3.18-1.9c1.27 0 2.19.75 2.44 1.9zM58 46.52a4.25 4.25 0 0 1-4.04 3.5c-1.42 0-3-1.19-2.53-3.4.44-2.06 2.13-3.62 3.96-3.62 1.84 0 3.02 1.55 2.6 3.53" />
                <path d="M90.32 45.46c-.1-1.4-.83-2.92-2.28-3.2.2.09.73.36 1.15.97.65.94.63 2.19.3 3.24-.75 2.34-3.03 4.52-6.15 5.5a10 10 0 0 0 3.2.83c2.43-1.8 4.01-4.48 3.79-7.34z" />
                <path d="M88.2 46.92c.82-1.14 1.25-2.77.32-3.92.08.19.31.74.24 1.48-.1 1.13-.93 2.07-1.87 2.67-2.08 1.31-5.21 1.53-8.25.27a10 10 0 0 0 1.93 2.69c3.03.18 5.95-.86 7.62-3.19zm-14.62-5.84a4.8 4.8 0 0 0-2.95-.94c-2.93.03-5.69 2.4-6.61 5.33l.95-5.24H62.2L59.98 52.4h2.78l.94-5.17c-.16 3.06 1.76 5.52 4.64 5.5a6.6 6.6 0 0 0 3.3-.98l-.12.65h2.78l2.21-12.18h-2.78zM73 46.52a4.34 4.34 0 0 1-4.12 3.5c-1.67 0-2.86-1.61-2.5-3.4.42-2.07 2.06-3.62 4.02-3.62s3 1.55 2.6 3.53" />
                <path d="M85.64 46.67c1.36-.35 2.74-1.32 2.77-2.8-.06.2-.24.77-.77 1.29-.81.8-2.04 1-3.14.85-2.44-.33-4.99-2.18-6.5-5.09a10 10 0 0 0-.25 3.3c2.2 2.07 5.11 3.16 7.88 2.45z" />
            </StyledGroup>
        </svg>
    );
});

MediaMarkt.displayName = 'MediaMarkt';
MediaMarkt.defaultProps = {
    className: '',
    height: 100,
    testId: 'MediaMarkt',
    width: 300
};

interface StyledGroupProps {
    $isLight?: boolean;
    $reactsToTheme?: boolean;
}

const StyledGroup = styled.g<StyledGroupProps>`
    fill: ${({$isLight}) => chooseTheme($isLight ? 'iconColorLight' : 'iconColor')};
`;